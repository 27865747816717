import styled from "styled-components";

export interface ContainerHeightProps {
	height: string;
}
export const MainContainerWithTopBar = styled.div<ContainerHeightProps>`
	padding: 2rem;
	max-width: 1240px;
	width: 100%;
	max-height: ${(props) => props.height};
	overflow: auto;
	min-height: ${(props) => props.height};
`;
