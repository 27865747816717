import React, { useRef } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "primereact/menu";
import { MainTopBar_UserDataFragment$key } from "@generated/MainTopBar_UserDataFragment.graphql";
import { useTypedDispatch } from "../../redux/redux-store";
import { DASHBOARD_PATH } from "../../../infecto-lms-webapp-impl/router/routes/auth/dashboard.routes";
import styled from "styled-components";
import { MAIN_MENU_ITEMS } from "../../../infecto-lms-webapp-impl/impl-main-menu-items";
import { ProfileLink } from "./ProfileLink.component";
import { logout } from "../../redux/slices/auth.slice";
import { FeedbackButtonV2 } from "../FeedbackButton.component";
import { ExternalSearchBar } from "../ExternalSearchBar.component";
import { PROFILE_LINK_MENU_ITEMS } from "../../../infecto-lms-webapp-impl/impl-profile-menu-items";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { ProjectLogoComponent } from "../../../infecto-lms-webapp-impl/components/ProjectLogo.component";
import { PrimaryButton } from "../buttons/PrimaryButton";

const USER_DATA = graphql`
	fragment MainTopBar_UserDataFragment on User {
		...ProfileLink_UserDataFragment
	}
`;

interface OwnProps {
	userDataFragmentRef: MainTopBar_UserDataFragment$key | null;
	className?: string;
	containerId?: string; // rename this to nodeId after launch
	screen?: string;
}

export function MainTopBar({ className, userDataFragmentRef, containerId, screen }: OwnProps) {
	const userData = useFragment<MainTopBar_UserDataFragment$key>(USER_DATA, userDataFragmentRef);

	const dispatch = useTypedDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const previousRouteStarted = localStorage.getItem("previousRouteStarted");
	const previousRouteContinue = localStorage.getItem("previousRouteContinue");
	const previousRouteAfterFailed = localStorage.getItem("previousRouteAfterFailed");
	const previousRouteAfterPassed = localStorage.getItem("previousRouteAfterPassed");

	const goBack = () => {
		if (location.pathname === previousRouteStarted) {
			navigate(-2);
			localStorage.setItem("previousRouteStarted", "");
		} else if (location.pathname === previousRouteContinue) {
			navigate(-2);
			localStorage.setItem("previousRouteContinue", "");
		} else if (location.pathname === previousRouteAfterFailed) {
			navigate(-2);
			localStorage.setItem("previousRouteAfterFailed", "");
		} else if (location.pathname === previousRouteAfterPassed) {
			navigate(-2);
			localStorage.setItem("previousRouteAfterPassed", "");
		} else {
			navigate(-1);
		}
	};
	const menuRef = useRef<Menu | null>(null);

	return (
		<div
			className={`border-bottom-1 border-300 p-3 flex white-background align-items-center ${
				className || ""
			}`}
		>
			{/modul/.test(location.pathname) && (
				<PrimaryButton
					className="mr-4 p-button-primary bg-primary border-primary"
					label="Zurück"
					onClick={() => goBack()}
					icon="pi pi-chevron-left"
				/>
			)}
			{LMS_WEBAPP_CONFIG_IMPL.alwaysShowLogoInMenuTopBar && (
				<NavLink to={DASHBOARD_PATH}>
					<ProjectLogoComponent width={window.innerWidth >= 1024 ? 250 : 150} />
				</NavLink>
			)}
			<div className="p-2 block md:hidden flex-grow-1 flex align-items-center">
				{!LMS_WEBAPP_CONFIG_IMPL.alwaysShowLogoInMenuTopBar && (
					<NavLink to={DASHBOARD_PATH}>
						<ProjectLogoComponent width={100} />
					</NavLink>
				)}
				<Menu
					ref={menuRef}
					popup
					model={[
						...MAIN_MENU_ITEMS("ProfileMenu", false),
						...PROFILE_LINK_MENU_ITEMS(() => dispatch(logout()), "ProfileMenu", false),
					]}
				/>
				<MenuBurgerButton
					className="ml-auto"
					icon="pi pi-align-justify"
					onClick={(e) => menuRef.current?.show(e)}
				/>
			</div>
			<div className="ml-auto hidden md:block">
				<FeedbackButtonV2 screen={screen} nodeId={containerId} />
			</div>

			<div className="ml-3 hidden md:block">
				{LMS_WEBAPP_CONFIG_IMPL.includeSearchbarInHeader && <ExternalSearchBar />}
			</div>
			<div className="hidden md:block">
				<ProfileLink userDataFragmentRef={userData} />
			</div>
		</div>
	);
}

const MenuBurgerButton = styled(PrimaryButton)`
	max-height: 2.357rem;
	max-width: 2.357rem;
`;
