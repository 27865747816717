import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ProjectSearchFilters } from "../../../infecto-lms-webapp-impl/components/relay/ProjectSearchFilters";
import { SearchFilterButton_QueryFragment$key } from "@generated/SearchFilterButton_QueryFragment.graphql";
import { PrimaryButton } from "../buttons/PrimaryButton";

const TAGS_FRAGMENT = graphql`
	fragment SearchFilterButton_QueryFragment on Query {
		...ProjectSearchFilters_QueryFragment
	}
`;

interface OwnProps {
	queryFragmentRef: SearchFilterButton_QueryFragment$key;
}

export function SearchFilterButton({ queryFragmentRef }: OwnProps) {
	const tags = useFragment<SearchFilterButton_QueryFragment$key>(TAGS_FRAGMENT, queryFragmentRef);
	const [isVisible, setVisible] = useState<boolean>(false);

	return (
		<>
			<PrimaryButton onClick={() => setVisible(true)} label="Filter" />
			<Dialog onHide={() => setVisible(false)} visible={isVisible}>
				<ProjectSearchFilters queryFragmentRef={tags} />
			</Dialog>
		</>
	);
}
