import React, { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import styled from "styled-components";
import { NewsTickerComponent_Query } from "@generated/NewsTickerComponent_Query.graphql";

const QUERY = graphql`
	query NewsTickerComponent_Query {
		Viewer {
			Newsticker {
				ActiveNewsTickers {
					id
					text
					forwardingUrl
				}
			}
		}
	}
`;

interface OwnProps {
	sideMenuWidth: number;
}

export function NewsTickerComponent(props: OwnProps) {
	const {
		Viewer: {
			Newsticker: { ActiveNewsTickers },
		},
	} = useLazyLoadQuery<NewsTickerComponent_Query>(QUERY, {}, { fetchPolicy: "network-only" });

	const [isAnimationActive, setIsAnimationActive] = useState(true);

	const toggleNewsTickerAnimation = (isActive: boolean) => {
		setIsAnimationActive(isActive);
	};

	return ActiveNewsTickers && ActiveNewsTickers.length > 0 ? (
		<NewsTickerContainer
			onMouseEnter={() => toggleNewsTickerAnimation(false)}
			onMouseLeave={() => toggleNewsTickerAnimation(true)}
			sideMenuWidth={props.sideMenuWidth}
			className={"bg-primary px-5"}
		>
			<NewsTickerText isAnimationActive={isAnimationActive}>
				{ActiveNewsTickers.map((item, index) => (
					<NewsTickerItem
						onClick={() => {
							if (item.forwardingUrl && item.forwardingUrl.length > 0) {
								// @ts-ignore
								window.open(item.forwardingUrl, "_blank").focus();
							}
						}}
						key={item.id}
					>
						+++ {item.text} {index === ActiveNewsTickers.length - 1 ? "+++" : ""}
					</NewsTickerItem>
				))}
			</NewsTickerText>
		</NewsTickerContainer>
	) : null;
}

const NewsTickerContainer = styled.div<{ sideMenuWidth: number }>`
	width: ${(props) => `calc(100vw - ${props.sideMenuWidth - 12}px)`};
	white-space: nowrap;

	@media screen and (max-width: 768px) {
		width: 100vw;
	}
`;

const NewsTickerText = styled.p<{ isAnimationActive: boolean }>`
	text-align: center;
	-moz-transform: translateX(100%);
	-webkit-transform: translateX(100%);
	transform: translateX(100%);

	-moz-animation: newsticker-scroll-animation 50s linear infinite;
	-webkit-animation: newsticker-scroll-animation 50s linear infinite;
	animation: newsticker-scroll-animation 35s linear infinite;

	-moz-animation-play-state: ${(props) => `${props.isAnimationActive ? "running" : "paused"}`};
	-webkit-animation-play-state: ${(props) => `${props.isAnimationActive ? "running" : "paused"}`};
	animation-play-state: ${(props) => `${props.isAnimationActive ? "running" : "paused"}`};

	@-moz-keyframes newsticker-scroll-animation {
		from {
			-moz-transform: translateX(100%);
		}
		to {
			-moz-transform: translateX(-100%);
		}
	}

	@-webkit-keyframes newsticker-scroll-animation {
		from {
			-webkit-transform: translateX(100%);
		}
		to {
			-webkit-transform: translateX(-100%);
		}
	}

	@keyframes newsticker-scroll-animation {
		from {
			-moz-transform: translateX(100%);
			-webkit-transform: translateX(100%);
			transform: translateX(100%);
		}
		to {
			-moz-transform: translateX(-100%);
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
		}
	}
`;

const NewsTickerItem = styled.span`
	cursor: pointer;
`;
