import { LmsWebAppConfiguration } from "../infecto-lms-webapp/config";

export const LMS_WEBAPP_CONFIG_IMPL: LmsWebAppConfiguration = {
	brandId: "QnJhbmQ6QnJhbmQ6ZTNiMTNlYzktNzZjMC00YTUyLWJkNDAtZjIxYWViMTU3MzJj",
	brandDomain: ".paedia.com",
	browserTabTitle: "Pädia -",
	browserTabDescription: "Gesundheit von Anfang an",
	multipleChoiceDownloadButtonText: "Übersicht Multiple-Choice-Fragen",
	includeSearchbarInHeader: false,
	showLogoInSideBar: false,
	alwaysShowLogoInMenuTopBar: true,
	widthLogoLoginPrompt: 300,
	privacyLink: "https://www.paedia.de/datenschutz/",
	brandColor: "#0099cc",
	fontFamily: "DaxOT",
};
