/**
 * @generated SignedSource<<791c890c4340e25d3840efa10fdf677e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeaturedNodesCarousel_RefetchQuery$variables = {
  after?: string | null;
  first?: number | null;
};
export type FeaturedNodesCarousel_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FeaturedNodesCarousel_QueryFragment">;
};
export type FeaturedNodesCarousel_RefetchQuery = {
  response: FeaturedNodesCarousel_RefetchQuery$data;
  variables: FeaturedNodesCarousel_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "date"
  },
  {
    "kind": "Literal",
    "name": "searchOptions",
    "value": [
      "hideFinished",
      "hideTreeNotRewarded",
      "onlyShowBasisSeminar"
    ]
  },
  {
    "kind": "Literal",
    "name": "sortDirection",
    "value": "desc"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeaturedNodesCarousel_RefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "FeaturedNodesCarousel_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeaturedNodesCarousel_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnV2Queries",
        "kind": "LinkedField",
        "name": "LearnV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "LearnOpportunitiesV2Connection",
            "kind": "LinkedField",
            "name": "SearchLearnOpportunities",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunitiesV2Edge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnOpportunityV2",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LearnOpportunityV2",
                        "kind": "LinkedField",
                        "name": "nextContentNode",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "typeDefinition",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "containedElementTypes",
                                    "storageKey": null
                                  }
                                ],
                                "type": "LearnOpportunityELearningContentTypeDefinition",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Tag",
                                "kind": "LinkedField",
                                "name": "tags",
                                "plural": true,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "LearnOpportunityRootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [
              "searchOptions",
              "orderBy",
              "sortDirection"
            ],
            "handle": "connection",
            "key": "Nodes_SearchLearnOpportunities",
            "kind": "LinkedHandle",
            "name": "SearchLearnOpportunities"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9f2a454c3a71efb01029e92ce84f17a",
    "id": null,
    "metadata": {},
    "name": "FeaturedNodesCarousel_RefetchQuery",
    "operationKind": "query",
    "text": "query FeaturedNodesCarousel_RefetchQuery(\n  $after: String\n  $first: Int\n) {\n  ...FeaturedNodesCarousel_QueryFragment_2HEEH6\n}\n\nfragment CarousalFeaturedNode_NodeFragment on LearnOpportunityV2 {\n  id\n  nextContentNode {\n    typeDefinition {\n      __typename\n      ... on LearnOpportunityELearningContentTypeDefinition {\n        containedElementTypes\n      }\n    }\n    id\n  }\n  structureDefinition {\n    __typename\n    title\n    ... on LearnOpportunityRootStructureDefinition {\n      tags {\n        id\n        name\n      }\n    }\n  }\n  image {\n    url\n    id\n  }\n}\n\nfragment FeaturedNodesCarousel_QueryFragment_2HEEH6 on Query {\n  LearnV2 {\n    SearchLearnOpportunities(first: $first, after: $after, searchOptions: [hideFinished, hideTreeNotRewarded, onlyShowBasisSeminar], orderBy: date, sortDirection: desc) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          ...CarousalFeaturedNode_NodeFragment\n          nextContentNode {\n            typeDefinition {\n              __typename\n              ... on LearnOpportunityELearningContentTypeDefinition {\n                containedElementTypes\n              }\n            }\n            id\n          }\n          structureDefinition {\n            __typename\n            title\n            ... on LearnOpportunityRootStructureDefinition {\n              tags {\n                name\n                id\n              }\n            }\n          }\n          image {\n            url\n            id\n          }\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "290225e83e8b238df94777f5e3de3f78";

export default node;
