import styled, { css } from "styled-components";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import React from "react";

interface LabelProps {
	fontFamily: string;
}
export const sharedLabelStyles = css<LabelProps>`
	font-family: ${(props) => props.fontFamily}
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
`;

export const LabelSpanContainer = styled.span<LabelProps>`
	${sharedLabelStyles}
`;

interface OwnProps {
	dangerouslySetInnerHTML?: any;
	children?: any;
}
export const Label = ({ dangerouslySetInnerHTML, children }: OwnProps) => {
	return (
		<LabelSpanContainer
			fontFamily={LMS_WEBAPP_CONFIG_IMPL.fontFamily}
			dangerouslySetInnerHTML={dangerouslySetInnerHTML}
		>
			{children}
		</LabelSpanContainer>
	);
};
