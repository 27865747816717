import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import React, { ReactNode } from "react";
import { MainTopBar } from "../components/navigation/MainTopBar.component";
import { MainSidebar } from "../components/navigation/MainSideBar.component";
import { DefaultContainer_UserDataFragment$key } from "@generated/DefaultContainer_UserDataFragment.graphql";

const USER_DATA = graphql`
	fragment DefaultContainer_UserDataFragment on User {
		...MainTopBar_UserDataFragment
	}
`;

interface OwnProps {
	userDataFragmentRef?: DefaultContainer_UserDataFragment$key | null;
	children: any;
	rightSideSlot?: ReactNode;
	containerId?: string;
	screenName: string;
	collapsed: boolean;
}

export function DefaultContainer({
	userDataFragmentRef,
	children,
	rightSideSlot,
	containerId,
	screenName,
	collapsed,
}: OwnProps) {
	const userData = useFragment<DefaultContainer_UserDataFragment$key>(
		USER_DATA,
		userDataFragmentRef || null,
	);

	return (
		<div className="flex flex-grow-1 max-h-screen">
			<MainSidebar collapsed={collapsed} className="border-right-1 border-300 hidden md:flex  " />

			<div className="flex flex-grow-1 flex-column">
				<MainTopBar
					containerId={containerId}
					screen={screenName}
					className="border-bottom-1 z-1"
					userDataFragmentRef={userData}
				/>

				<div className="flex flex-grow-1 surface-100">
					<div className=" flex-grow-1  justify-content-center flex">{children}</div>
					{rightSideSlot}
				</div>
			</div>
		</div>
	);
}
