import { useState, useEffect } from "react";

export function defineDynamicHeightForContainers() {
	const [height, setHeight] = useState("calc(100vh - 70px)");

	useEffect(() => {
		function calculateMaxHeight() {
			const height = window.innerHeight;
			const width = window.innerWidth;
			let extraHeight = "";

			if (height >= 700 && height <= 1025 && width >= 700) {
				extraHeight = "+ 100";
			} else if (height >= 600 && height <= 699) {
				extraHeight = "+ 160";
			} else if (height >= 500 && height <= 599) {
				extraHeight = "+ 260";
			} else if (height >= 400 && height <= 499) {
				extraHeight = "+ 350";
			} else if (height <= 399) {
				extraHeight = "+ 400";
			} else {
				extraHeight = `- 70`;
			}

			setHeight(`calc(100vh ${extraHeight}px)`);
		}

		calculateMaxHeight();
		window.addEventListener("resize", calculateMaxHeight);

		return () => {
			window.removeEventListener("resize", calculateMaxHeight);
		};
	}, []);

	return height;
}
