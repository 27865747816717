import styled from "styled-components";

interface OwnProps {
	footerPixels: number;
	marginBottom: number;
	width: number;
}
export const WrapperContainerWithScrollbar = styled.div<OwnProps>`
	@media only screen and (min-width: 1024px) {
		overflow-y: auto;
		&:hover {
			::-webkit-scrollbar-thumb {
				background-color: rgba(0, 0, 0, 0.5);
				-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
			}
		}
		::-webkit-scrollbar {
			width: 7px;
		}

		::-webkit-scrollbar-thumb {
			border-radius: 4px;
		}
		padding-bottom: ${(props) => props.footerPixels}px;
		margin-bottom: ${(props) => props.marginBottom}px;
		width: ${(props) => props.width}%;
	}
`;
