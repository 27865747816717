import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import styled from "styled-components";
import { ProgressBar } from "primereact/progressbar";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { FachberaterStatus_UserFragment$key } from "@generated/FachberaterStatus_UserFragment.graphql";
import moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";
import { FachberaterStatus_Query } from "@generated/FachberaterStatus_Query.graphql";
import {
	formatDateTime,
	stripUTC,
} from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import LogoPaediaAkademie from "../../app/assets/LogoPadiaAkademie.png";
import { FachberaterStatus_LegacyPacPointsQuery } from "@generated/FachberaterStatus_LegacyPacPointsQuery.graphql";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";
import { PrimaryButton } from "../../infecto-lms-webapp/components/buttons/PrimaryButton";
/*import LogoPaediaInfectopharm from "../../infecto-lms-webapp-impl/assets/LogosPaediaInfectopharm.png";*/

const QUERY = graphql`
	query FachberaterStatus_Query {
		Viewer {
			Rewards {
				UserRewards {
					receivedAt
					receivedForRootNodeTitle
					rewardResult {
						rewardKind
						... on PharmaPointsRewardWasPossibleResult {
							amount
						}

						... on PacPointsRewardWasPossibleResult {
							amount
						}
					}
				}
			}

			FachberaterCertificates {
				currentFachberaterCertificate {
					certificateFile {
						name
						url
					}
				}
			}
		}
	}
`;

const LEGACY_PAC_POINTS_QUERY = graphql`
	query FachberaterStatus_LegacyPacPointsQuery {
		Viewer {
			LegacyPacPointsAchievedByUser {
				legacyPacPoints {
					node {
						structureDefinition {
							title
						}
					}
					receivedAt
					pacPointsAmount
				}
			}
		}
	}
`;

const FRAGMENT = graphql`
	fragment FachberaterStatus_UserFragment on User {
		extension {
			... on InfectopharmUserExtensionImpl {
				fachberaterStatus {
					statusType

					... on IsFachberater {
						needsToBeCheckedAfter
						changes {
							receivedAt
							amount
							kind
							... on LegacyPacPointAddition {
								amount
							}
							... on ImportedPacPointAddition {
								eventTitle
								eventDate
								eventType
								eventNumber
								amount
							}
						}
					}
				}
				fachberaterStatusHistory {
					... on IsFachberater {
						needsToBeCheckedAfter
						changes {
							receivedAt
							amount
							kind
							... on LegacyPacPointAddition {
								amount
							}
							... on ImportedPacPointAddition {
								eventTitle
								eventDate
								eventType
								eventNumber
								amount
							}
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	userFragmentRef: FachberaterStatus_UserFragment$key;
}

export const FachberaterStatus = ({ userFragmentRef }: OwnProps) => {
	const user = useFragment<FachberaterStatus_UserFragment$key>(FRAGMENT, userFragmentRef);
	const query = useLazyLoadQuery<FachberaterStatus_Query>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);
	const legacyPacPointsQuery = useLazyLoadQuery<FachberaterStatus_LegacyPacPointsQuery>(
		LEGACY_PAC_POINTS_QUERY,
		{},
		{},
	);

	const isFachberater = user.extension.fachberaterStatus?.statusType == "isFachberater";

	const pacPointsSum = isFachberater
		? user.extension.fachberaterStatus.changes
			? user.extension.fachberaterStatus.changes
					.filter((c) => c.kind !== "statusExtension")
					.map((p) => p.amount)
					.reduce((a, b) => a + b, 0) -
			  user.extension.fachberaterStatus.changes
					.filter((c) => c.kind == "statusExtension")
					.map((p) => p.amount)
					.reduce((a, b) => a + b, 0)
			: 0
		: 0;

	const visitedEvents = user.extension.fachberaterStatus?.changes
		? user.extension.fachberaterStatus.changes?.filter(
				(c) => c.kind === "manuallyImported" && c.eventType === "BesuchtePraesenzfortbildungen",
		  )
		: [];

	const historyVisitedEvents =
		user.extension.fachberaterStatusHistory &&
		user.extension.fachberaterStatusHistory.flatMap((h) => h.changes)
			? user.extension?.fachberaterStatusHistory
					.flatMap((h) => h.changes)
					.filter(
						(ch) =>
							ch?.kind === "manuallyImported" &&
							ch?.eventType === "BesuchtePraesenzfortbildungen" &&
							!visitedEvents.find((ve) => ve.eventNumber == ch?.eventNumber),
					)
			: [];

	const combinedVisitedEvents = [...visitedEvents, ...historyVisitedEvents];

	const sortedCombinedVisitedEventsByDate = combinedVisitedEvents.sort((a, b) => {
		if (a && b && a.receivedAt && b.receivedAt) {
			return b.receivedAt.localeCompare(a.receivedAt);
		} else return 0;
	});

	const thirdPartyEvents = user.extension.fachberaterStatus?.changes
		? user.extension?.fachberaterStatus.changes?.filter(
				(c) => c.kind === "manuallyImported" && c.eventType === "AbsolvierteLernerfolgskontrollen",
		  )
		: [];

	const historyThirdPartyEvents =
		user.extension.fachberaterStatusHistory &&
		user.extension.fachberaterStatusHistory.flatMap((h) => h.changes)
			? user.extension.fachberaterStatusHistory
					.flatMap((h) => h.changes)
					.filter(
						(ch) =>
							ch?.kind === "manuallyImported" &&
							ch?.eventType === "AbsolvierteLernerfolgskontrollen",
					)
			: [];

	const combinedThirdPartyEvents = [...thirdPartyEvents, ...historyThirdPartyEvents];

	interface PointsProps {
		receivedForRootNodeTitle: string;
		receivedAt: string;
		rewardResult: { amount: number | undefined; kind: string };
	}

	const legacyPointsArray: PointsProps[] =
		legacyPacPointsQuery.Viewer.LegacyPacPointsAchievedByUser.legacyPacPoints.map((element) => ({
			["receivedForRootNodeTitle"]: element.node.structureDefinition.title,
			["receivedAt"]: element.receivedAt,
			["rewardResult"]: {
				["amount"]: element.pacPointsAmount,
				["kind"]: "legacy",
			},
		}));

	const thirdPartyPointsArray: PointsProps[] = combinedThirdPartyEvents.map((point) => ({
		["receivedForRootNodeTitle"]: point?.eventTitle as string,
		["receivedAt"]: point?.receivedAt as string,
		["rewardResult"]: {
			["amount"]: point?.amount,
			["kind"]: "manuallyImported",
		},
	}));

	const lmsUserRewardsArray: PointsProps[] = query.Viewer.Rewards.UserRewards.map((reward) => ({
		["receivedForRootNodeTitle"]: reward.receivedForRootNodeTitle,
		["receivedAt"]: reward.receivedAt,
		["rewardResult"]: {
			["amount"]: reward.rewardResult.amount,
			["kind"]: reward.rewardResult.rewardKind.toString(),
		},
	}));

	const unifiedArray = lmsUserRewardsArray.concat(legacyPointsArray).concat(thirdPartyPointsArray);

	const sortedDataByDate = unifiedArray.sort((a, b) => {
		return b.receivedAt.localeCompare(a.receivedAt);
	});

	const arrayAwardedAndLegacyPoints: Record<
		string,
		{ receivedAt: string; rewardKind: string; amount: number | undefined }[]
	> = {};

	sortedDataByDate.map((reward) => {
		if (
			reward.rewardResult.kind == "PacPoints" ||
			reward.rewardResult.kind == "PharmaPoints" ||
			reward.rewardResult.kind == "legacy"
		) {
			// @ts-ignore
			arrayAwardedAndLegacyPoints[reward.receivedForRootNodeTitle] = [
				// @ts-ignore
				...(arrayAwardedAndLegacyPoints[reward.receivedForRootNodeTitle] ?? []),
				{
					receivedAt: reward.receivedAt,
					rewardKind: reward.rewardResult.kind,
					amount: reward.rewardResult.amount,
				},
			];
		} else if (reward.rewardResult.kind == "manuallyImported") {
			// @ts-ignore
			arrayAwardedAndLegacyPoints[reward.receivedForRootNodeTitle] = [
				{
					receivedAt: reward.receivedAt,
					rewardKind: reward.rewardResult.kind,
					amount: reward.rewardResult.amount,
				},
			];
		}
	});

	const valueTemplate = (value: number) => {
		const newValue = Math.floor((100 * value) / 60);

		return (
			<div className="ml-2">
				{newValue}
				<b>%</b>
			</div>
		);
	};

	const rewardedNodeOrEvent = (key: string, date: string, title: string, rewards: string) => (
		<tr>
			<td colSpan={3} className="vertical-align-top mr-3 mt-1 mb-1 w-5rem lg:w-6rem">
				{date}
			</td>
			<td colSpan={9} className="mt-1 mb-1 vertical-align-top ">
				{title.trim().replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "")} {rewards}
			</td>
		</tr>
	);

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const updateWindowWidth = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", updateWindowWidth);

		return () => {
			window.removeEventListener("resize", updateWindowWidth);
		};
	}, []);

	const positionOfProgressText =
		windowWidth < 460 ? `${windowWidth * 0.5}` : `${windowWidth * 0.27}`;

	return (
		<Card className="m-1 p-2 md:m-2 md:p-4 lg:m-4 lg:p-6">
			<LogoPaediaAkademieContainer className="mb-4">
				<img alt="Paedia" src={LogoPaediaAkademie} width={200} />
			</LogoPaediaAkademieContainer>

			{isFachberater && (
				<div className="mb-4">
					<h3 className="text-primary mb-0">Mein aktueller Fortbildungs-Kontostand</h3>
					<GreyText className="mt-1 mb-2">{pacPointsSum} PädiaAkademie Credits (PAC)</GreyText>
					<ProgressBarContainer>
						<TextContainer position={positionOfProgressText}>
							60 PAC = Rezertifizierung
						</TextContainer>

						<div className="relative">
							<ProgressBarMarker className="absolute"></ProgressBarMarker>

							<ProgressBarStyled
								color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
								value={pacPointsSum}
								displayValueTemplate={valueTemplate}
							/>
						</div>
					</ProgressBarContainer>
					<h3 className="text-primary mt-5 mb-0">Zertifikatsgültigkeit</h3>
					{isFachberater && (
						<div>
							<GreyText className="mt-2">
								Mein aktuelles Zertifikat „Fachberater*in Pädiatrie“ ist bis zum{" "}
								{moment(stripUTC(user.extension.fachberaterStatus.needsToBeCheckedAfter!)).format(
									"DD.MM.YYYY",
								)}{" "}
								gültig
							</GreyText>

							{query.Viewer.FachberaterCertificates.currentFachberaterCertificate
								?.certificateFile && (
								<div>
									{" "}
									<a
										target="_blank"
										rel="noopener noreferrer"
										download
										href={
											query.Viewer.FachberaterCertificates.currentFachberaterCertificate
												?.certificateFile.url!
										}
									>
										<PrimaryButton
											label={"Fachberater Zertifikat Herunterladen"}
											className="bg-primary"
										></PrimaryButton>
									</a>
								</div>
							)}
						</div>
					)}
					{pacPointsSum >= 60 && (
						<p>
							Herzlichen Glückwunsch! Sie haben 60 PAC erreicht und sind rezertifiziert. Nach Ablauf
							Ihres aktuell gültigen Zertifikats finden Sie Ihr neues Zertifikat mit verlängerter
							Gültigkeit unter „Meine Zertifikate“.
						</p>
					)}
					<Accordion activeIndex={1} className={"mt-5 mb-5 w-full"}>
						<AccordionTab header="Wie kann ich mein Zertifikat verlängern?">
							<p className="m-0">
								Um die Gültigkeit Ihres Zertifikats "Fachberater*in Pädiatrie" aufrechtzuerhalten,
								gilt es innerhalb von drei Jahren mindestens 60 Pädia
								<FontBoldSpan>Akademie</FontBoldSpan>
								-Credits (PAC) zu sammeln. Sie erhalten dann das aktualisierte Zertifikat mit der
								Gültigkeit von weiteren drei Jahren. Dafür stehen Ihnen die Pädia
								<FontBoldSpan>Akademie</FontBoldSpan> Themenhefte, Onlineschulungen und
								Präsenzveranstaltungen zur Verfügung.
							</p>
						</AccordionTab>
					</Accordion>{" "}
				</div>
			)}

			<div>
				<h3 className="text-primary mt-4 mb-2">Absolvierte Lernerfolgskontrollen</h3>

				{Object.keys(arrayAwardedAndLegacyPoints).length == 0 && (
					<p className="mt-1 mb-1">Sie haben noch keine absolvierten Lernerfolgskontrollen.</p>
				)}

				{Object.keys(arrayAwardedAndLegacyPoints).length > 0 && (
					<table className="mt-0 mb-0 ">
						{Object.keys(arrayAwardedAndLegacyPoints).map((key) =>
							rewardedNodeOrEvent(
								key,
								formatDateTime(
									// @ts-ignore
									arrayAwardedAndLegacyPoints[key][0].receivedAt,
									false,
								),
								key,
								arrayAwardedAndLegacyPoints[key]
									.filter(
										(reward) =>
											reward.rewardKind == "PacPoints" ||
											reward.rewardKind.includes("legacy") ||
											reward.rewardKind.includes("manuallyImported"),
									)
									.map(
										(reward, index) =>
											` ${index == 0 ? "-" : " "} ${reward.amount} ${
												reward.rewardKind == "PacPoints" ||
												reward.rewardKind.includes("legacy") ||
												reward.rewardKind.includes("manuallyImported")
													? " PAC "
													: " BAK "
											}`,
									)
									.join(" "),
							),
						)}
					</table>
				)}
			</div>

			{sortedCombinedVisitedEventsByDate && sortedCombinedVisitedEventsByDate.length > 0 && (
				<div className="mb-4">
					<h3 className="text-primary mt-4 mb-2">Besuchte Präsenzfortbildungen</h3>

					{sortedCombinedVisitedEventsByDate.map((visitedEvent) =>
						rewardedNodeOrEvent(
							visitedEvent?.eventTitle as string,
							visitedEvent?.eventDate.toString().split("-").reverse().join("."),
							`${visitedEvent?.eventTitle} -`,
							`${visitedEvent?.amount} PAC`,
						),
					)}
				</div>
			)}

			{/*		<LogoPaediaInfecto className="mb-4">
				<img alt="Paedia Infectopharm" src={LogoPaediaInfectopharm} width={250} />
			</LogoPaediaInfecto>*/}
		</Card>
	);
};

const ProgressBarContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const ProgressBarMarker = styled.div`
	height: 100%;
	z-index: 10;
	width: 60%;
	border-right-style: solid;
	border-right-color: #55b14a;
	border-right-width: 4px;
`;
export interface ColorProps {
	color: string;
}

const ProgressBarStyled = styled(ProgressBar)<ColorProps>`
	.p-progressbar {
		height: 100% !important;
	}

	.p-progressbar-value {
		justify-content: flex-start !important;
		background: ${(props) => props.color} !important;
	}
`;

interface TextProps {
	position: string;
}

const TextContainer = styled.div<TextProps>`
	width: ${(props) => props.position}px;
	padding-bottom: 10px;
	align-self: flex-end;
	color: #55b14a;
`;

const GreyText = styled.p`
	font-size: 1.2em;
	color: #808080;
	@media screen and (max-width: 480px) {
		font-size: 1.1em;
	}
`;

/*const LogoPaediaInfecto = styled.div`
	margin-left: -15px;
`;*/

const LogoPaediaAkademieContainer = styled.div`
	margin-left: -10px;
`;

const FontBoldSpan = styled.span`
	font-family: "DaxOT-Bold";
`;
