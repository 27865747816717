/**
 * @generated SignedSource<<6101a1748a4454d2d46f2eac2d1b57cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeaturedNodesCarousel_QueryFragment$data = {
  readonly LearnV2: {
    readonly SearchLearnOpportunities: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly image: {
            readonly url: string | null;
          } | null;
          readonly nextContentNode: {
            readonly typeDefinition: {
              readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
            };
          } | null;
          readonly structureDefinition: {
            readonly tags?: ReadonlyArray<{
              readonly name: string;
            }>;
            readonly title: string;
          };
          readonly " $fragmentSpreads": FragmentRefs<"CarousalFeaturedNode_NodeFragment">;
        };
      } | null> | null;
      readonly pageInfo: {
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
    };
  };
  readonly " $fragmentType": "FeaturedNodesCarousel_QueryFragment";
};
export type FeaturedNodesCarousel_QueryFragment$key = {
  readonly " $data"?: FeaturedNodesCarousel_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeaturedNodesCarousel_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "LearnV2",
  "SearchLearnOpportunities"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./FeaturedNodesCarousel_RefetchQuery.graphql')
    }
  },
  "name": "FeaturedNodesCarousel_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnV2Queries",
      "kind": "LinkedField",
      "name": "LearnV2",
      "plural": false,
      "selections": [
        {
          "alias": "SearchLearnOpportunities",
          "args": [
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": "date"
            },
            {
              "kind": "Literal",
              "name": "searchOptions",
              "value": [
                "hideFinished",
                "hideTreeNotRewarded",
                "onlyShowBasisSeminar"
              ]
            },
            {
              "kind": "Literal",
              "name": "sortDirection",
              "value": "desc"
            }
          ],
          "concreteType": "LearnOpportunitiesV2Connection",
          "kind": "LinkedField",
          "name": "__Nodes_SearchLearnOpportunities_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnOpportunitiesV2Edge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LearnOpportunityV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "CarousalFeaturedNode_NodeFragment"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LearnOpportunityV2",
                      "kind": "LinkedField",
                      "name": "nextContentNode",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "typeDefinition",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "containedElementTypes",
                                  "storageKey": null
                                }
                              ],
                              "type": "LearnOpportunityELearningContentTypeDefinition",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "structureDefinition",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Tag",
                              "kind": "LinkedField",
                              "name": "tags",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "name",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "LearnOpportunityRootStructureDefinition",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "File",
                      "kind": "LinkedField",
                      "name": "image",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "__Nodes_SearchLearnOpportunities_connection(orderBy:\"date\",searchOptions:[\"hideFinished\",\"hideTreeNotRewarded\",\"onlyShowBasisSeminar\"],sortDirection:\"desc\")"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "290225e83e8b238df94777f5e3de3f78";

export default node;
